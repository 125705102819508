<div class="container">

	<div class="ads-left-container">
		<app-ads class="ads" [ads]="leftSideAds" *ngIf="leftSideAds !== null"></app-ads>
	</div>
	<div class="ads-right-container">
		<app-ads class="ads" [ads]="rightSideAds" *ngIf="rightSideAds !== null"></app-ads>
	</div>
	
	<app-ads id="top-ads" [ads]="getAds(109)" *ngIf="getAds(109) && !newsDetail.adsDisabled && newsDetail.displayAdsBefore"></app-ads>
	<app-ads id="top-ads" [ads]="getAds(110)" *ngIf="getAds(110) && !newsDetail.adsDisabled && newsDetail.displayAdsBefore"></app-ads>
	
    <div id="news-detail">
        <div id="news-detail-left">
            <h1 class="news-detail-title">{{newsDetail.title}}</h1>

            <!-- TOPICS -->
			<div class="news-detail-top-line">
				<div class="news-detail-topics">
					<a class="news-detail-topic" [title]="topic.name" [routerLink]="['/', topic.slug]" *ngFor="let topic of topics" [ngStyle]="{'background-color': topic.backgroundColor, 'color': topic.textColor}">{{topic.name}}</a>
				</div>
				<div class="news-detail-source-and-publication-date">
					<span *ngIf="newsDetail.editorName !== null && newsDetail.editorName !== ''">{{newsDetail.editorName}} | </span>
					{{newsDetail.contentSource}} | <span>{{newsDetail.publicationFromDate|date:'yyyy. MM. dd. | HH:mm:ss'}}</span>
				</div>
				<div class="share-buttons">
					<img src="assets/images/facebook.png" shareButton="facebook" />
					<img src="assets/images/twitter.png" shareButton="twitter" />
					<img src="assets/images/link.png" (click)="copyUr()" />
					<img src="assets/images/mail.png" (click)="sendEmail()" />
				</div>
			</div>
            
            <div class="news-detail-subtitle">{{newsDetail.subtitle}}</div>

            <div class="main-image-container" *ngIf="newsDetail.displayMainMediaOnDetail && newsDetail.mainMedia !== null">
                <img [src]="newsDetail.mainMedia.url + '?size=850'" [alt]="newsDetail.title" />
				<div class="main-image-caption" *ngIf="newsDetail.mainMedia.caption">{{newsDetail.mainMedia.caption}} <span *ngIf="newsDetail.mainMedia.source !== ''">(Fotó: {{newsDetail.mainMedia.source}})</span></div>
            </div>

			<div id="image-banners">
				<app-ads id="top-ads" [ads]="getAds(106)" *ngIf="getAds(106) && !newsDetail.adsDisabled && newsDetail.displayAdsBefore"></app-ads>
				<app-ads id="top-ads" [ads]="getAds(107)" *ngIf="getAds(107) && !newsDetail.adsDisabled && newsDetail.displayAdsBefore"></app-ads>
			</div>

            <app-ads id="top-ads" [ads]="getAds(1)" *ngIf="getAds(1) && !newsDetail.adsDisabled && newsDetail.displayAdsBefore"></app-ads>
            <app-ads id="top-ads" [ads]="getAds(113)" *ngIf="getAds(113) && !newsDetail.adsDisabled && newsDetail.displayAdsBefore"></app-ads>
            <app-ads id="top-ads" [ads]="getAds(114)" *ngIf="getAds(114) && !newsDetail.adsDisabled && newsDetail.displayAdsBefore"></app-ads>
            
            <div #newsDetailContentDiv class="news-detail-content">
				<ng-container *ngFor="let block of newsDetail.content; let i = index;">
					<ng-container *ngIf="block.type === NewsEditorContentBlockType.EDITOR">
						<div class="editor-block" (click)="clicked($event)" [innerHTML]="block.data|safehtml"></div>
					</ng-container>
					<ng-container *ngIf="block.type === NewsEditorContentBlockType.EDITOR && firstTextBlockIndex === i">
						<div class="flex-ads" *ngIf="(getAds(392) || getAds(393)) && !newsDetail.adsDisabled">
							<app-ads id="top-ads" [ads]="getAds(392)" *ngIf="getAds(392)"></app-ads>
							<app-ads id="top-ads" [ads]="getAds(393)" *ngIf="getAds(393)"></app-ads>
						</div>
						<app-ads id="top-ads" [ads]="getAds(394)" *ngIf="getAds(394) && !newsDetail.adsDisabled"></app-ads>
						<app-ads id="top-ads" [ads]="getAds(395)" *ngIf="getAds(395) && !newsDetail.adsDisabled"></app-ads>
						<app-ads id="top-ads" [ads]="getAds(396)" *ngIf="getAds(396) && !newsDetail.adsDisabled"></app-ads>
					</ng-container>
					<ng-container *ngIf="block.type === NewsEditorContentBlockType.YOUTUBE">
						<iframe width="100%" height="400px" [src]="getSafeYoutubeUrl(block.data)" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</ng-container>
					<ng-container *ngIf="block.type === NewsEditorContentBlockType.MEDIA && innerMedia[block.data] !== undefined">
						<div class="inner-media">
							<img [src]="innerMedia[block.data].url" *ngIf="innerMedia[block.data].type === MediaType.IMAGE" class="inner-media-image" />
							<div *ngIf="innerMedia[block.data].caption" class="inner-media-caption">{{innerMedia[block.data].caption}} <span *ngIf="innerMedia[block.data].source !== ''">(Fotó: {{innerMedia[block.data].source}})</span></div>
						</div>
					</ng-container>
					<ng-container *ngIf="block.type === NewsEditorContentBlockType.TWITTER || block.type === NewsEditorContentBlockType.INSTAGRAM || block.type === NewsEditorContentBlockType.TIKTOK || block.type === NewsEditorContentBlockType.EMBED_HTML">
						<ng-container *ngIf="block.type === NewsEditorContentBlockType.EMBED_HTML">
							<div [innerHTML]="block.data|safehtml"></div>
						</ng-container>
						<ng-container *ngIf="block.type !== NewsEditorContentBlockType.EMBED_HTML">
							<div class="centered-include">
								<div class="centered-include-content" [innerHTML]="block.data|safehtml"></div>
							</div>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="block.type === NewsEditorContentBlockType.FACEBOOK">
						<div class="centered-include">
							<div class="centered-include-content" [innerHTML]="block.data|safehtml"></div>
						</div>
					</ng-container>
					<ng-container *ngIf="block.type === NewsEditorContentBlockType.VIDEA">
						<div class="centered-include">
							<div class="centered-include-content" [innerHTML]="block.data|safehtml"></div>
						</div>
					</ng-container>
					<ng-container *ngIf="block.type === NewsEditorContentBlockType.VOTING && innerVotings[block.data] !== undefined">
						<div class="vote">
							<div class="question">
								<div class="question-mark">?</div>
								<div class="question-text">{{innerVotings[block.data].question}}</div>
							</div>
							<div class="answers">
								<div class="answer" *ngFor="let answer of innerVotings[block.data].answers" (click)="vote(innerVotings[block.data], answer.answerId)">
									<div class="answer-checkbox" [ngClass]="{'voted': innerVotings[block.data].votedAnswerId === answer.answerId}" *ngIf="!innerVotings[block.data].closed">
										<img src="assets/images/check.svg" />
									</div>
									<div class="answer-data">
										<div class="answer-title-line">
											<span class="answer-title">{{answer.answer}}</span>
											<span class="answer-value" *ngIf="innerVotings[block.data].votedAnswerId || innerVotings[block.data].closed">{{answer.percent}}%</span>
										</div>
										<div class="answer-bg" *ngIf="innerVotings[block.data].votedAnswerId || innerVotings[block.data].closed">
											<div class="answer-bg-inner" [ngStyle]="{'width.%': answer.percent}"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
					<!--<ng-container *ngIf="block.type === NewsEditorContentBlockType.NEWS && innerNews[block.data] !== undefined">
						<a [routerLink]="['/hir', innerNews[block.data].slug]" [title]="innerNews[block.data].title" class="inner-news">
							<div class="inner-news-left"><img src="assets/images/link.jpg" /></div>
							<div class="inner-news-right">
								<div class="inner-news-title">{{innerNews[block.data].title}}</div>
								<div class="inner-news-lead">{{innerNews[block.data].subtitle}}</div>
							</div>
						</a>
					</ng-container>-->
					<ng-container *ngIf="block.type === NewsEditorContentBlockType.NEWS_MULTIPLE">
						<ng-container *ngIf="block.data.length === 1">
							<a [routerLink]="['/hir', innerNews[block.data[0]].slug]" [title]="innerNews[block.data[0]].title" class="inner-news">
								<div class="inner-news-left"><img src="assets/images/link.jpg" /></div>
								<div class="inner-news-right">
									<div class="inner-news-title">{{innerNews[block.data[0]].title}}</div>
									<div class="inner-news-lead">{{innerNews[block.data[0]].subTitle}}</div>
								</div>
							</a>
						</ng-container>
						<ng-container *ngIf="block.data.length > 1">
							<div class="inner-news multiple">
								<div class="inner-news-left"><img src="assets/images/link.jpg" /></div>
								<div class="inner-news-right">
									<a class="inner-news-title" [routerLink]="['/hir', innerNews[newsId].slug]" [title]="innerNews[newsId].title" *ngFor="let newsId of block.data">{{innerNews[newsId].title}}</a>
								</div>
							</div>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="block.type === NewsEditorContentBlockType.ADS">
						<ng-container *ngIf="innerAds[block.data] !== undefined">
							<app-ads [ads]="innerAds[block.data]"></app-ads>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="block.type === NewsEditorContentBlockType.TABLE">
						<div class="table-content" [innerHTML]="convertToTable(block.data.header, block.data.src)"></div>
					</ng-container>
					<ng-container *ngIf="block.type === NewsEditorContentBlockType.GALLERY">
						<div id="gallery" *ngIf="galleryMain !== null">
							<img id="gallery-prev" src="assets/images/left.png" (click)="galleryPrev()" />
							<img id="gallery-next" src="assets/images/right.png" (click)="galleryNext()" />
							<div id="gallery-title">Kattintson a további képekért!</div>
							<div id="gallery-items" scroll>
								<img class="gallery-item" [src]="galleryItem.url" [ngClass]="{'active': i === galleryImageIndex}" (click)="imageClicked(i)" *ngFor="let galleryItem of newsDetail.gallery; let i = index;" />
							</div>
							<img id="gallery-main-image" [src]="galleryMain.url" (click)="openLightGallery()" />
						</div>
					</ng-container>
					<ng-container *ngIf="block.type === NewsEditorContentBlockType.FILE">
						<a [href]="apiBaseUrl + '/news/files/' + block.data.guid" [download]="block.data.name + '.' + block.data.extension" [target]="'_blank'" class="download">
							<div class="donwload-icon"><img src="assets/images/download.png" /></div>
							<div class="text">Letöltés: {{block.data.name + '.' + block.data.extension}}</div>
						</a>
					</ng-container>
				</ng-container>
			</div>

            <!-- LIVE ENTRIES -->
            <ng-container *ngIf="newsDetail.contentType === NewsType.LIVE && newsDetail.liveType === LiveType.TEAMSPORT">
                <div id="live-entries-info">
                    <div id="live-entries-info-team-1">{{newsDetail.homeTeam}}</div>
                    <div id="live-entries-info-points">
                        <div id="live-entries-info-point-1" [ngClass]="{'lower': newsDetail.homeScore < newsDetail.guestScore}">{{newsDetail.homeScore}}</div>
                        <div id="live-entries-info-point-2" [ngClass]="{'lower': newsDetail.guestScore < newsDetail.homeScore}">{{newsDetail.guestScore}}</div>
                    </div>
                    <div id="live-entries-info-team-2">{{newsDetail.guestTeam}}</div>
                </div>
                <div id="live-entries">
                    <div class="live-entry" *ngFor="let liveEntry of newsDetail.liveEntries">
                        <div class="live-entry-header"></div>
                        <div class="live-entry-body">
                            <div class="live-entry-body-title">{{liveEntry.title}}</div>
                            <div class="live-entry-body-description">{{liveEntry.content}}</div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <app-ads id="middle-ads" [ads]="getAds(2)" *ngIf="getAds(2) && !newsDetail.adsDisabled && newsDetail.displayAdsAfter"></app-ads>
            <app-ads id="middle-ads" [ads]="getAds(115)" *ngIf="getAds(115) && !newsDetail.adsDisabled && newsDetail.displayAdsAfter"></app-ads>
            <app-ads id="middle-ads" [ads]="getAds(116)" *ngIf="getAds(116) && !newsDetail.adsDisabled && newsDetail.displayAdsAfter"></app-ads>

            <!--<div id="tags" *ngIf="newsDetail.tags.length > 0">
                <span>Címkék:</span>
                <a class="tag" [routerLink]="['/cimke', tag]" *ngFor="let tag of newsDetail.tags">{{tag}}</a>
            </div>-->

			<div class="rltdwidget" [attr.data-widget-id]="relatedWidgetId"></div>
			
            <app-ads id="middle-ads" [ads]="getAds(380)" *ngIf="getAds(380) && !newsDetail.adsDisabled && newsDetail.displayAdsAfter"></app-ads>
            <app-ads id="middle-ads" [ads]="getAds(381)" *ngIf="getAds(381) && !newsDetail.adsDisabled && newsDetail.displayAdsAfter"></app-ads>
            <app-ads id="middle-ads" [ads]="getAds(382)" *ngIf="getAds(382) && !newsDetail.adsDisabled && newsDetail.displayAdsAfter"></app-ads>

			<!--<div [attr.data-cfaid]="popularWidgetId"></div>-->
            <div id="more-news" *ngIf="newsDetail.similarNews.length > 0">
                <div id="more-news-title">További híreink</div>
				<div id="more-news-blocks">
					<app-news [news]="newsItem" [displayImage]="true" [displaySubtitle]="true" *ngFor="let newsItem of newsDetail.similarNews"></app-news>
				</div>
            </div>
			
            <app-ads id="bottom-ads" [ads]="getAds(371)" *ngIf="getAds(371) && !newsDetail.adsDisabled && newsDetail.displayAdsAfter"></app-ads>
            <app-ads id="bottom-ads" [ads]="getAds(372)" *ngIf="getAds(372) && !newsDetail.adsDisabled && newsDetail.displayAdsAfter"></app-ads>
            <app-ads id="bottom-ads" [ads]="getAds(373)" *ngIf="getAds(373) && !newsDetail.adsDisabled && newsDetail.displayAdsAfter"></app-ads>

        </div>
        <div id="news-detail-right">
			<div class="news-block" *ngFor="let pageBlock of newsDetail.pageBlocks; let i = index;">
				<div class="block-selector-name" *ngIf="pageBlock.name !== ''">
					<div class="block-selector-name-inner" [ngStyle]="{'background-color': pageBlock.backgroundColor, 'color': pageBlock.textColor}">{{pageBlock.name}}</div>
				</div>
				<div class="block-selector-line" *ngIf="i > 0 || pageBlock.name !== ''"></div>
				<div class="news-grid">
					<div class="news-grid-column" *ngFor="let columnConfiguration of pageBlock.columnConfigurations">
						<ng-container *ngFor="let content of columnConfiguration.contents">
							<ng-container *ngIf="content.ads !== null">
								<app-ads [ads]="content.ads"></app-ads>
							</ng-container>
							<ng-container *ngIf="content.news !== null">
								<app-news
									[news]="content.news"
									[displaySubtitle]="content.displaySubtitle"
									[displayImage]="content.displayImage"
									[titleSize]="content.titleSize"
								></app-news>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</div>
        </div>
    </div>

	<app-ads id="top-ads" [ads]="getAds(108)" *ngIf="getAds(108) && !newsDetail.adsDisabled && newsDetail.displayAdsBefore"></app-ads>
	<app-ads id="top-ads" [ads]="getAds(111)" *ngIf="getAds(111) && !newsDetail.adsDisabled && newsDetail.displayAdsBefore"></app-ads>
	<app-ads id="top-ads" [ads]="getAds(112)" *ngIf="getAds(112) && !newsDetail.adsDisabled && newsDetail.displayAdsBefore"></app-ads>
</div>

<div class="age-restriction" *ngIf="newsDetail.ageRestricted">
	<div class="age-restriction-content">
		<div class="age-restriction-number">18</div>
		<div class="age-restriction-data">
			<div class="age-restriction-title">Csak felnőtteknek</div>
			<div class="age-restriction-description">A következő oldal tartalma a kiskorúakra káros lehet. Ha korlátozná a korhatáros tartalmak elérését a gépén, használjon, <a href="https://mte.hu/gyermekbarat-internet/" target="_blank">szűrőprogramot</a>!</div>
			<div class="age-restriction-text">Az oldal tartalma az Mttv. által rögzített besorolás szerinti V. vagy VI. kategóriába tartozik.</div>
			<div class="age-restriction-buttons">
				<div class="age-restriction-button" (click)="acceptAgeRestriction()">Elmúltam 18 éves, belépek</div>
				<div class="age-restriction-button danger" (click)="declineAgeRestriction()">Még nem vagyok 18 éves</div>
			</div>
		</div>
	</div>
</div>

<div class="popup-ad" *ngIf="popupAdVisible && popupAds !== null">
	<div class="ad">
		<div class="ad-close" (click)="hidePopupAd()">X Bezárás</div>
		<app-ads [ads]="popupAds"></app-ads>
	</div>
</div>