<div class="container">
	<div id="topic">
		<div id="left">
			<div id="title">
				<span id="topic-name" [ngStyle]="{'color': topic.backgroundColor}">{{topic.name}}</span>
			</div>
			<div id="news-list">
				<a [routerLink]="['/hir', newsItem.slug]" [title]="newsItem.title" class="news" *ngFor="let newsItem of news">
					<div class="news-image-holder" [ngStyle]="{'background-image': 'url(' + newsItem.imageUrl + '?size=360)'}" *ngIf="newsItem.imageUrl !== null"></div>
					<div class="news-data">
						<div class="news-data-date">{{newsItem.publicationDate|date:'yyyy.MM.dd HH:mm:ss'}}</div>
						<div class="news-data-title" [ngStyle]="newsItem.titleStyleProcessed !== null && {'color': newsItem.titleStyleProcessed.color, 'background-color': newsItem.titleStyleProcessed.backgroundColor}">{{newsItem.title}}</div>
						<div class="news-data-subtitle">{{newsItem.subTitle}}</div>
					</div>
				</a>
			</div>
			<div class="load-more" (click)="nextPage()">Továbbiak betöltése</div>
		</div>
		<div id="right">
		</div>
	</div>
</div>